import React, { Component } from 'react';
import Block from "./block";
import Page from '../page';

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header leftSide={<Page.NewButton title="Add activity category" name="ActivityCategory"/>}>
          Activity Categories
        </Page.Header>

        <Block
          title="Activity Category"
          path="/admin/activity_categories"
          name="ActivityCategory"
        />
      </div>
    );
  }
}
