import React, { Component } from 'react';
import { FormGroup, InputGroup, FormControl, DropdownButton, MenuItem, Button } from 'react-bootstrap';
import axios from 'axios';
import FieldGroup from "../smart/field_group";

export default class Edit extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleUpdate = () => {
    const { path, id, onUpdate } = this.props;
    let data = new FormData(this.formRef.current);

    axios
      .put(`${path}/bills/${id}.json`, data)
      .then((response) => { onUpdate() })
      .catch((error) => console.log(error));
  };

  render() {
    const { amount, reason } = this.props.overall_discount_item;

    return (
      <div className="be_boxed vert-offset-bottom-20">
        <form className="form-horizontal" ref={this.formRef}>
          <input type="hidden" name="bill[overall_discount_measure]" value="sum"/>

          <FieldGroup
            formType="inline"
            type="text"
            label="Venue price adjustment"
            name="bill[overall_discount]"
            defaultValue={amount}
            custom={{
              colLabelStyle: { width: 225 },
              colInputStyle: { width: 160 }
            }}
          />

          <FieldGroup
            formType="inline"
            type="text"
            label="Reason for price adjustment"
            name="bill[overall_discount_reason]"
            defaultValue={reason}
            custom={{
              colLabelStyle: { width: 225 }
            }}
          />

          <div className="pull-right">
            <Button bsStyle="default" onClick={this.handleUpdate}>
              Update
            </Button>
          </div>

          <div className="clearfix" />
        </form>
      </div>
    );
  }
}