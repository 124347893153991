import React, { Component } from 'react';
import classnames from 'classnames';
import { Can } from '../ability_context';
import { AbilityContext } from '../ability_context';

const MenuItemsWidth = 1152;

const mainMenu = [
  { name: 'venues', title: 'Venues', className: 'venues', link: '/admin/venues' },
  { name: 'activities', title: 'Activities', className: 'activities', link: '/admin/activities' },
  { name: 'calendars', title: 'Calendar', className: 'calendar', link: '/admin/calendar' },
  { name: 'bookings', title: 'Bookings', className: 'finances', link: '/admin/bookings' },
  { name: 'messages', title: 'Messages', className: 'messages', link: '/admin/messages' },
  { name: 'customers', title: 'Customers', className: 'customers', link: '/admin/customers' },
  { name: 'reports', title: 'Reports', className: 'reports', link: '/admin/reports' },
  { name: 'users', title: 'Users', className: 'users', link: '/admin/users' },
];

function capitalize(string) { return string.charAt(0).toUpperCase() + string.slice(1) };

class MenuItem extends Component {
  render() {
    const {
      menuItemWidth,
      name,
      className,
      title,
      link,
      ability,
      active
    } = this.props;

    let innerEl = null;

    const visible = ability.can(`see${capitalize(name)}`, 'AdminMenu');

    let liClassNames = classnames({ 'active': active === name });
    let aClassNames = classnames(className, { 'greyed-out': !visible });

    if (visible) {
      innerEl = <a className={aClassNames} href={link}> {title}</a>
    } else {
      innerEl = <a className={aClassNames} href='#' onClick={(e) => e.preventDefault()}> {title}</a>
    }

    return <li style={{ width: menuItemWidth }} className={liClassNames}>{innerEl}</li>;
  }
}

export default class Menu extends Component {
  render() {
    const { active } = this.props;
    const menuItemWidth = MenuItemsWidth / mainMenu.length;

    return (
      <AbilityContext.Consumer>
        {ability =>
          <ul className="nav nav-tabs" role="tablist">
            {mainMenu.map((item, index) =>
              <MenuItem
                key={index}
                menuItemWidth={menuItemWidth}
                active={active}
                ability={ability}
                {...item}
              />
            )}
            <Can I="seeSettings" a="AdminMenu">
              <li className="settings" role="presentation">
                <div className="dropdown" id="settings">
                  <a aria-expanded="true"
                     aria-haspopup="true"
                     data-target="#"
                     data-toggle="dropdown"
                     href="#"
                     role="button">
                  </a>
                  <ul className="dropdown-menu">
                    <li><a href="/admin/info_pages">Content</a></li>
                    <li><a href="/admin/configurations">Configuration</a></li>
                  </ul>
                </div>
              </li>
            </Can>
          </ul>
        }
      </AbilityContext.Consumer>
    )
  }
}