import React, { Component } from 'react';
import CheckoutMenu from './checkout_menu';
import AttendeeQuestions from './attendee_questions/index';
import Questions from './questions/index';
import TermsAndConditions from './terms_and_conditions/index';
import DetailedSummary from './detailed_summary/index';
import Timer from './Timer';
import { toastr } from "react-redux-toastr";

export default class Index extends Component {
  constructor(props) {
    super(props);

    this.state = { step: 0, timer: 15 * 60, trigger: new Date() };
  }

  changeStep = (step) => this.setState({ step }, () => window.scrollTo(0, 0));
  handleBack = () => this.changeStep(this.state.step - 1);
  handleNext = () => this.changeStep(this.state.step + 1);

  handleUpdateTimer = (seconds) => this.setState({ timer: seconds, trigger: new Date() });

  showPage() {
    const { step } = this.state;

    const params = {
      onBack: this.handleBack,
      onNext: this.handleNext,
      bookingId: window.PageData.booking_id,
      onUpdatedTimer: this.handleUpdateTimer
    };

    const steps = [
      <AttendeeQuestions {...params}/>,
      <Questions {...params}/>,
      <TermsAndConditions {...params}/>,
      <DetailedSummary {...params}/>
    ];

    return steps[step];
  }

  handleTimerExpire = () => {
    window.location.href = '/'
  }

  render() {
    const { step, timer, trigger } = this.state;

    return (
      <div className="container" id="main">
        <div className='pull-right' style={{ marginTop: 20 }}>
          <Timer seconds={timer} trigger={trigger} onExpire={this.handleTimerExpire}/>
        </div>
        <h1>Booking</h1>
        <div className="row">
          <div className="col-md-3">
            <CheckoutMenu step={step} onChangeStep={this.changeStep}/>
          </div>
          <div className="col-md-9 col-xs-12">
            {this.showPage()}
          </div>
        </div>
      </div>
    )
  };
}
