import React from 'react';
import { appendComponents } from '../render';

import MainMenu from './menu';
import BookingsSubmenu from './bookings/subtabs';
import ReportsSubmenu from './reports/subtabs';
import ConfigurationSubmenu from './configurations/subtabs';

import VenuesIndex from './venues/index';
import VenuesShow from './venues/show';
import ActivitiesIndex from './activities/index';
import ActivitiesShow from './activities/show';
import UsersIndex from './users/index';
import BookingsIndex from './bookings/index';
import BookingsShow from './bookings/show';
import MetaTagsIndex from './meta_tags/index';
import LocalAuthoritiesIndex from './local_authorities/index';
import RegionsIndex from './regions/index';
import CitiesIndex from './cities/index';
import CountiesIndex from './counties/index';
import VenueCollectionsIndex from './venues_collections/index';
import InfoPagesIndex from './info_pages/index';
import ActivityCategoriesIndex from './activity_categories/index';
import CustomersIndex from './customers/index';
import CalendarsIndex from './calendars/index';
import MessagesIndex from './messages/index';
import InvoicesIndex from './invoices/index';
import ReportsActivitiesShow from './reports/activities/show'
import ReportsBookingsShow from './reports/bookings/show'
import ReportsCustomersShow from './reports/customers/show'
import ReportsRemittanceShow from './reports/remittance/show'
import ApplicationSettingsShow from './application_settings/index';
import VenueActivityCategoriesShow from './venue_activity_categories/show';
import InternalNotesIndex from './internal_notes/index';

const EmptySubMenu = (props) => <div/>;

appendComponents({
  admin_venues_index: {
    side: 'admin',
    menu: <MainMenu active="venues"/>,
    submenu: <EmptySubMenu/>,
    component: <VenuesIndex/>
  },
  admin_venues_show: {
    side: 'admin',
    menu: <MainMenu active="venues"/>,
    submenu: <EmptySubMenu/>,
    component: <VenuesShow/>
  },
  admin_activities_index: {
    side: 'admin',
    menu: <MainMenu active="activities"/>,
    submenu: <EmptySubMenu/>,
    component: <ActivitiesIndex/>
  },
  admin_activities_show: {
    side: 'admin',
    menu: <MainMenu active="activities"/>,
    submenu: <EmptySubMenu/>,
    component: <ActivitiesShow/>
  },
  admin_venue_activity_categories_show: {
    side: 'admin',
    menu: <MainMenu active="activities"/>,
    submenu: <EmptySubMenu/>,
    component: <VenueActivityCategoriesShow/>
  },
  admin_bookings_index: {
    side: 'admin',
    menu: <MainMenu active="bookings"/>,
    submenu: <BookingsSubmenu/>,
    component: <BookingsIndex/>
  },
  admin_bookings_show: {
    side: 'admin',
    menu: <MainMenu active="bookings"/>,
    submenu: <EmptySubMenu/>,
    component: <BookingsShow/>
  },
  admin_users_index: {
    side: 'admin',
    menu: <MainMenu active="users"/>,
    submenu: <EmptySubMenu/>,
    component: <UsersIndex/>
  },
  admin_activity_categories_index: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <ConfigurationSubmenu active="activity_categories"/>,
    component: <ActivityCategoriesIndex/>
  },
  admin_local_authorities_index: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <ConfigurationSubmenu active="local_authorities"/>,
    component: <LocalAuthoritiesIndex/>
  },
  admin_regions_index: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <ConfigurationSubmenu active="regions"/>,
    component: <RegionsIndex />
  },
  admin_cities_index: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <ConfigurationSubmenu active="cities"/>,
    component: <CitiesIndex/>
  },
  admin_counties_index: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <ConfigurationSubmenu active="counties"/>,
    component: <CountiesIndex/>,
  },
  admin_venue_collections_index: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <ConfigurationSubmenu active="venue_collections"/>,
    component: <VenueCollectionsIndex/>
  },
  admin_info_pages_index: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <EmptySubMenu/>,
    component: <InfoPagesIndex/>
  },
  admin_reports_activities_show: {
    side: 'admin',
    menu: <MainMenu active="reports"/>,
    submenu: <ReportsSubmenu active='activity'/>,
    component: <ReportsActivitiesShow/>
  },
  admin_reports_bookings_show: {
    side: 'admin',
    menu: <MainMenu active="reports"/>,
    submenu: <ReportsSubmenu active='bookings'/>,
    component: <ReportsBookingsShow/>
  },
  admin_reports_customers_show: {
    side: 'admin',
    menu: <MainMenu active="reports"/>,
    submenu: <ReportsSubmenu active='customers'/>,
    component: <ReportsCustomersShow/>
  },
  admin_reports_remittance_show: {
    side: 'admin',
    menu: <MainMenu active="reports"/>,
    submenu: <ReportsSubmenu active='remittance'/>,
    component: <ReportsRemittanceShow/>
  },
  admin_customers_index: {
    side: 'admin',
    menu: <MainMenu active="customers"/>,
    submenu: <EmptySubMenu/>,
    component: <CustomersIndex/>
  },
  admin_calendars_show: {
    side: 'admin',
    menu: <MainMenu active="calendars"/>,
    submenu: <EmptySubMenu/>,
    component: <CalendarsIndex/>
  },
  admin_messages_index:  {
    side: 'admin',
    menu: <MainMenu active="messages"/>,
    submenu: <EmptySubMenu/>,
    component: <MessagesIndex/>
  },
  admin_meta_tags_index: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <EmptySubMenu/>,
    component: <MetaTagsIndex/>
  },
  admin_invoices_index: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <EmptySubMenu/>,
    component: <InvoicesIndex/>
  },
  admin_internal_notes_show: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <EmptySubMenu/>,
    component: <InternalNotesIndex/>
  },
  admin_application_settings_show: {
    side: 'admin',
    menu: <MainMenu/>,
    submenu: <ConfigurationSubmenu active="application_settings"/>,
    component: <ApplicationSettingsShow/>
  },
});