import React, { Component } from 'react';
import ParentSubTabs from '../subtabs';

export default class Submenu extends Component {
  render() {
    const { active } = this.props;

    return (
      <ParentSubTabs
        active={active}
        items={[
          { name: 'activity_categories', title: 'Activity categories', href: '/admin/activity_categories' },
          { name: 'regions', title: 'Regions', href: '/admin/regions' },
          { name: 'counties', title: 'Counties', href: '/admin/counties' },
          { name: 'cities', title: 'Cities', href: '/admin/cities' },
          { name: 'local_authorities', title: 'Local authorities', href: '/admin/local_authorities' },
          { name: 'application_settings', title: 'Settings', href: '/admin/application_settings' }
        ]}
      />
    )
  }
}
