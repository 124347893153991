import React, { Component } from 'react';
import Block from "./block";
import Page from '../page';

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header leftSide={<Page.EditButton title="Edit settings" name="Settings"/>}>
          Settings
        </Page.Header>

        <Block
          title="Settings"
          path="/admin/application_settings"
          name="Settings"
        />
      </div>
    );
  }
}
