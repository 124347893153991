import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { UserContext } from '../user';
import { Can } from '../ability_context';
import MessagesButton from './header/messages_button';
import LoginButton from './header/login_button';

class Menu extends Component {
  signOut(e, user) {
    e.preventDefault();
    axios.delete('/users/sign_out.json').then(() => {
      //user.change({ name: null });
      window.location.replace('/')
    });
  }

  render() {
    const { user } = this.props;

    return (
      <ul className="nav navbar-nav navbar-right">
        <nav style={{ position: 'relative', float: 'right', left: 0 }}>
          <ul className="topmenu">
            <Can I="index" a="AccountMessages">
              <MessagesButton path='/account/messages'/>
            </Can>
            <Can I="index" a="AdminMessages">
              <MessagesButton path='/admin/messages'/>
            </Can>
            <li className="dropdown">
              <a className="dropdown-toggle dropdown" data-toggle="dropdown" href="#" id="dLabel" role="button">
                <i>
                  <img style={{ maxWidth: 32, maxHeight: 32 }} src=""/>
                </i>
                {user.name}
                <span className="caret"/>
              </a>
              <ul aria-labelledby="dLabel" className="dropdown-menu">
                <li><a href="/account/profile">My account</a></li>
                <Can I="index" a="AccountBookings">
                  <li><a href="/account/bookings">My bookings</a></li>
                </Can>
                <Can I="index" a="SystemAdministrator">
                  <li><a href="/admin">System administration</a></li>
                </Can>
                <Can I="index" a="VenueManagement">
                  <li><a href="/admin">Venue management</a></li>
                </Can>
                <li className="divider"/>
                <li>
                  <a rel="nofollow" href="#" onClick={(e) => this.signOut(e, user)}>
                    <i className="fa fa-sign-out"/>
                    Sign out
                  </a>
                </li>
                <Can I="back" a="SwitchUser">
                  <Fragment>
                    {user.switch_user &&
                    <Fragment>
                      <li className="divider"/>
                      <li>
                        <a href={user.switch_user.path}>
                          <i className="fa fa-sign-out"/>{user.switch_user.name}
                        </a>
                      </li>
                    </Fragment>
                    }
                  </Fragment>
                </Can>
              </ul>
            </li>
          </ul>
        </nav>
      </ul>
    );
  }
}

export default class UserMenu extends Component {
  render() {
    return (
      <UserContext.Consumer>
        {user =>
          <Fragment>
            {user.isLoggedIn() && <Menu user={user}/>}
            {!user.isLoggedIn() && <LoginButton/>}
          </Fragment>
        }
      </UserContext.Consumer>
    );
  }
}
